<template>
  <div class="container">
    <div class="row">
      <div
        class="col-12 col-md-6 col-xxl-3 mb-4 mt-2"
        v-for="profileType in profileTypes"
        :key="profileType.value"
      >
        <base-card
          class="h-100"
          @click.native="$emit('profile-type-select', profileType.value)"
          @keyup.native.enter="$emit('profile-type-select', profileType.value)"
          @keyup.native.space="$emit('profile-type-select', profileType.value)"
          role="button"
          tabindex="0"
        >
          <div class="content">
            <div class="img-wrapper">
              <icon
                :icon="profileType.value"
                :color="'#F77F00'"
                width="80"
                height="80"
              />
            </div>
            <h2 class="my-3">{{ profileType.title }}</h2>
            <p class="text-center">{{ profileType.description }}</p>
          </div></base-card
        >
      </div>
      <div class="proceed-btn">
        <default-button
          :labelText="'Next →'"
          @click="$emit('go-to-profile-complete')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-type-select",
  data() {
    return {
      profileTypes: [
        {
          value: "candidate",
          title: "Job Seeker",
          description:
            "Entry level or skilled worker looking to start or elevate your career in the skilled trades industry.",
        },
        {
          value: "provider",
          title: "Company",
          description:
            "Business or independent contractor that provides services in the construction or skilled trades industry",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  .card {
    cursor: pointer;
    padding: 2rem;
    transition: border 0.2s linear;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border: 2px solid var(--gfb-blue-brand-50, #c8ecff);
    border-radius: 12px;
    min-height: unset;
    margin: 0 !important;
    display: flex;
    justify-content: center;

    &:hover {
      border-color: #7cd2ff !important;
    }

    &:focus,
    :focus-within {
      outline: none;
      border-color: #7cd2ff;
    }
  }

  .proceed-btn {
    text-align: center;
    width: 100%;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-wrapper {
    background: #ffe2b0;
    border-radius: 50%;
    height: 115px;
    width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h2 {
    line-height: 140%;
    color: #343946;
    font-size: 30px;
    margin-top: 24px !important;
    margin-bottom: 16px !important;
  }

  p {
    color: var(--Gray-800, #3a4252);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 0 !important;
  }
}
</style>
