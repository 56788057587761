<template>
  <div>
    <profile-type-select
      v-show="!profileType"
      @profile-type-select="handleProfileTypeSelect"
      @go-to-profile-complete="handleGoToProfileComplete"
    ></profile-type-select>
    <candidate-profile-create v-if="profileType === 'candidate'" />
    <company-profile-create
      v-if="profileType === 'provider' || profileType === 'vendor'"
    />
  </div>
</template>

<script>
import CandidateProfileCreate from "@/components/Profile/Candidate/CandidateProfileCreate"
import CompanyProfileCreate from "@/components/Profile/Company/CompanyProfileCreate"
import ProfileTypeSelect from "@/components/Profile/ProfileTypeSelect"
export default {
  components: {
    CandidateProfileCreate,
    CompanyProfileCreate,
    ProfileTypeSelect,
  },
  data() {
    return {
      profileType: null,
    }
  },
  methods: {
    handleProfileTypeSelect: function (profileType) {
      localStorage.setItem("profileType", profileType)
    },
    handleGoToProfileComplete: function () {
      const profileType = localStorage.getItem("profileType", profileType)
      this.$router
        .replace(`/complete-profile?profileType=${profileType}`)
        .catch((error) => {
          if (
            error.name !== "NavigationDuplicated" &&
            !error.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(error)
          }
        })
      this.profileType = profileType
    },
  },
}
</script>

<style lang="scss" scoped></style>
